<template>
  <div class="container" >


    <div class="div1">大伟嘉简介</div>
    <div class="div2">VICA introduction</div>
<div class="qqq">
      <div class="div3">
       <img class="www" :src="data.pic" />
      </div>
      <div class="one">{{data.title}}</div>
      <p class="two" v-html="data.content">{{data.content}}</p>

</div>
    </div>

</template>

<script>
import {getAboutList} from "../../api/zoujindaweijia"
  export default {
    name: "A",
    data(){
      return{
        data:[]
      }
    },
     mounted() {
      getAboutList().then(res=>{
        // console.log(res.data);
        let data = res.data[0];
        // console.log(data);
        this.data = data;
      })

    }
  }
</script>

<style scoped>
.container{
  /*position: relative;*/
  /*height: 52.8646vw;*/
}
.div1{
height: 2.0833vw;
font-size: 2.2917vw;
font-family: PingFang SC, PingFang SC-Bold;
font-weight: 700;
color: #d7000f;
margin-top: 4.1146vw;
  text-align: center;
  letter-spacing:0.15vw;
}
.div2{
height: 1.0417vw;
font-size: 1.3542vw;
font-family: PingFang SC, PingFang SC-Regular;
font-weight: 400;
color: #646464;
margin-top: 1.0417vw;
  text-align: center;
}
.qqq{
  margin-top: 6vw;
}
.div3{
 margin-left:7.8125vw ;
 /*margin-top: 2.8vw!important;*/
}
.www{
width: 29.6875vw!important;
height: 20.8438vw!important;
  margin-top: 4.4vw!important;
  padding-bottom: 0!important;
  padding-top: 0!important;
}
/deep/img{
  width: 49.7917vw;
  padding-top:1.5vw ;
  /*padding-bottom: 0.5vw;*/
}
/deep/span{
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: rgb(153, 153, 153)!important;
  font-size: 14px!important;
  line-height: 30px;
  font-family: "Microsoft YaHei", 微软雅黑, 华文细黑, STHeiti, MingLiu !important;
  white-space: normal;
}
/deep/p{
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: rgb(153, 153, 153)!important;
  font-size: 14px!important;
  line-height: 30px;
  font-family: "Microsoft YaHei", 微软雅黑, 华文细黑, STHeiti, MingLiu !important;
  white-space: normal;
}

.one{
margin-left: 42.2396vw;
margin-top: -26.1vw;
  width: 20.2708vw;
  height: 1.7188vw;
  font-size: 1.875vw;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.two{
width: 49.7917vw!important;
font-size: 1.0417vw!important;
font-family: PingFang SC, PingFang SC-Regular;
font-weight: 400;
color: #000000;
margin-left: 42.1875vw;
margin-top: 2.8vw;
  margin-bottom: 8vw;
}
/deep/p{
  text-indent: 0!important;
}
</style>